import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import AgencyImage from "../images/AgencyImage"
import ShipImage from "../images/ShipImage"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { transformPathToEpi } from "../../utils/redirectHelpers"

const ActionLink = styled(OutboundLink)`
  text-decoration: inherit;
`

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  padding: 1rem;
  background-color: white;
  margin: 1rem;
  text-align: center;
  align-items: flex-start;
  box-shadow: 1px 1px 4px 0px #b3b3b3;
  grid-gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    background-color: white;
    padding: 0.2rem;
    margin: 10px 10px;
  }
`

const OrderButton = styled.button`
  padding: 0.5rem 4rem;
  background-color: #e87e01;
  border-radius: 2px;
  border: none;
  outline: none;
  color: white;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color: #f16603;
  }
`

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px;
  align-items: center;
`

const LightFont = styled.div`
  font-size: 0.6rem;
  margin-bottom: 1rem;
  font-weight: 400;
  color: #565656;
  text-transform: uppercase;
`

const LightFontDesc = styled.div`
  font-size: 10px;
  font-weight: 300;
  color: #8f8f8f;
  text-transform: uppercase;
`

const ShipText = styled.div`
  font-size: 10px;
  font-weight: 800;
  color: #565656;
  text-transform: uppercase;
`

const PriceText = styled.div`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: black;
`

const Route = styled.div`
  font-size: 12px;
  color: #0481b1;
  font-weight: 600;
`

const TimeTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 30px;
`
const Time = styled.div`
  margin: 0.3rem;
  font-size: 12px;
`
const TimeTableContainer = styled.div`
  text-align: center;
`
const DescText = styled.div`
  font-size: 1rem;
  padding: 0rem 2rem;
  font-weight: 400;
  line-height: 25px;
  color: #033a60;
  text-transform: capitalize;
`

const ResultCard = props => {
  const departures = props.departures.map((dep, i) => {
    return <Time key={i}>{dep}</Time>
  })
  const returns = props.returns.map((r, i) => {
    return <Time key={i}>{r}</Time>
  })
  const isCruise =
    props.type.toLowerCase().includes("risteily") ||
    props.innerType.toLowerCase().includes("risteily")
  return (
    <>
      <ActionLink rel="nofollow" href={props.link} target="_blank">
        <Card>
          <CardItem>
            <LightFont>Matka</LightFont>
            <DescText>{props.name}</DescText>
          </CardItem>
          <CardItem>
            <LightFont>Hinta</LightFont>
            <PriceText>Alk {props.price} €/hlö</PriceText>
          </CardItem>
          {isCruise ? (
            <CardItem>
              <LightFont>Vierailuaika {props.city}ssa</LightFont>
              <DescText>{props.visitTime} tuntia</DescText>
            </CardItem>
          ) : (
            ""
          )}
          <CardItem>
            <LightFont>Laiva</LightFont>
            <ShipImage imgName={props.shipImg} />
            <ShipText>{props.ship}</ShipText>
          </CardItem>
          <CardItem>
            <LightFont>Aikataulut</LightFont>
            <LightFontDesc>Mahdolliset lähtöajat</LightFontDesc>
            <TimeTableContainer>
              <Route>Helsinki-{props.city}</Route>
              <TimeTable>{departures}</TimeTable>
              {!isCruise ? (
                <>
                  <Route>{props.city}-Helsinki</Route>{" "}
                  <TimeTable>{returns}</TimeTable>
                </>
              ) : (
                ""
              )}
            </TimeTableContainer>
          </CardItem>
          <CardItem>
            <OrderButton>Valitse</OrderButton>
            <AgencyImage imgName={props.agency} />
          </CardItem>
        </Card>
      </ActionLink>
    </>
  )
}

export default ResultCard
