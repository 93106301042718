import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import AgencyImage from "../images/AgencyImage"

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  padding: 25px;
  margin: 20px;
  text-align: center;
  align-items: flex-start;
  box-shadow: 3px 5px 8px 8px rgba(0, 0, 0, 0.2);
  grid-gap: 20px;
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    background-color: white;
    padding: 25px 10px;
    margin: 10px 10px;
  }
`

const OrderButton = styled.button`
  padding: 15px 25px;
  background-color: #e87e01;
  border: none;
  outline: none;
  color: white;
  font-size: 17px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color: #f16603;
  }
`

const CardItem = styled.div`
  display: block;
  margin: 10px;
  align-items: center;
`

const LightFont = styled.div`
  font-size: 18px;
  font-weight: 200;
  color: #565656;
  text-transform: uppercase;
`

const LightFontDesc = styled.div`
  font-size: 15px;
  font-weight: 200;
  color: orange;
  text-transform: uppercase;
  margin-top: 30px;
`

const CityText = styled.div`
  font-size: 25px;
  font-weight: 200;
  color: black;
  text-transform: uppercase;
  margin-top: 30px;
`

const PriceText = styled.div`
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 30px;
`

const DescText = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  color: black;
  text-transform: uppercase;
`
const BenefitItem = styled.div`
  margin-top: 30px;
  font-weight: 600;
  color: #0481b1;
`

const ResultCard = props => {
  const benefitsList = props.benefits.map(b => {
    return <BenefitItem>{b}</BenefitItem>
  })
  return (
    <Card>
      <CardItem>
        <LightFont>Matka</LightFont>
        <AgencyImage imgName={props.agency} />
        <DescText>{props.name}</DescText>
      </CardItem>
      <CardItem>
        <LightFont>Hinta</LightFont>
        <PriceText>Alk {props.price} €/hlö</PriceText>
      </CardItem>
      <CardItem>
        <LightFont>Kaupunki</LightFont>
        <CityText>{props.city}</CityText>
      </CardItem>
      <CardItem>
        <LightFont>Miksi tämä?</LightFont>
        <LightFontDesc>{benefitsList}</LightFontDesc>
      </CardItem>
      <CardItem>
        <a rel="nofollow" href={props.link} target="_blank">
          <OrderButton>Tutustu >></OrderButton>
        </a>
      </CardItem>
    </Card>
  )
}

export default ResultCard
