import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"

const Image = styled(Img)`
  margin-bottom: 20px;
  margin-top: 30px;
  width: 150px;
  @media ${device.tablet} {
    width: 200px;
    margin-bottom: 10px;
  }
`

const AgencyImage = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Image fluid={image.node.fluid} alt={imgName.split(".")[0]} />
    }}
  />
)
export default AgencyImage
