import { useState, useEffect, useCallback } from "react"

export const useProducts = (products ,inner) => {
  const [originalProducts] = useState(products)
  const [filteredProducts, setFilteredProducts] = useState(originalProducts)
  const [filter, setFilter] = useState("")
  const [innerFilter, setInnerFilter] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [initialStart, setInitialStart] = useState(true)
  const [isInner] = useState(inner)

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  },[isLoading])

  useEffect(() => {
    if (initialStart) {
      setInitialStart(false)
    } else {
      filterProducts()
    }
  }, [filter, initialStart])

  const setNewFilter = filter => {
    setFilter(filter)
  }

  const setNewInnerFilter = filter => {
    setInnerFilter(filter)
  }

  const resetFilter = () => setFilter("")

  const resetInnerFilter = () => setInnerFilter("")


  const filterProducts = () => {
    setIsLoading(true)
    const newProducts = originalProducts.filter(product => {
      if(isInner){
        return product.node.innerType.includes(innerFilter)
      } else {
        return product.node.type.includes(filter)
      }
    })
    setFilteredProducts(newProducts)
  }

  const resetProducts = () => setFilteredProducts(originalProducts)

  return {
    filteredProducts,
    isLoading,
    filter,
    resetFilter,
    resetProducts,
    resetInnerFilter,
    innerFilter,
    setNewFilter,
    setNewInnerFilter
  }
}
