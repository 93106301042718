import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import ResultCard from "./ResultCard"
import HotelCard from "./HotelCard"
import RomanticCard from "./RomanticCard"

const Container = styled.div`
  display: grid;
  padding: 3rem 12rem;
  background-color: #ebeced;
  @media ${device.tablet} {
    padding: 0px;
  }
`

const Text = styled.h3`
  font-size: 18px;
  color: black;
  text-align: center;
`

const ResultHeader = props => {
  const products = props.products
  const allProducts = products.map(p => {
    if (props.hotels) {
      return (
        <HotelCard
          key={p.node.id}
          name={p.node.name}
          price={p.node.price}
          agency={p.node.agency}
          innerType={p.node.innerType}
          type={p.node.type}
          link={p.node.link}
          benefits={p.node.benefits}
          rating={p.node.rating}
          location={p.node.location}
        />
      )
    }
    if (p.node.type.includes("romanttinenviikonloppu")) {
      return (
        <RomanticCard
          key={p.node.id}
          name={p.node.name}
          price={p.node.price}
          agency={p.node.agency}
          innerType={p.node.innerType}
          type={p.node.type}
          city={p.node.city}
          link={p.node.link}
          benefits={p.node.benefits}
          location={props.location}
          slug={p.node.slug}
        />
      )
    }
    return (
      <ResultCard
        key={p.node.id}
        link={p.node.link}
        name={p.node.name}
        price={p.node.price}
        length={p.node.length}
        ship={p.node.ship}
        departures={p.node.departures}
        returns={p.node.return}
        agency={p.node.agency}
        shipImg={p.node.shipImg}
        city={p.node.city}
        type={p.node.type}
        innerType={p.node.innerType}
        visitTime={p.node.visitTime}
        location={props.location}
        slug={p.node.slug}
      />
    )
  })
  return (
    <Container>
      <Text>Valitse sinulle sopiva matka alapuolelta:</Text>
      {allProducts}
    </Container>
  )
}

export default ResultHeader
